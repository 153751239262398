import { $, $$, setAttributes } from '@/3pc/js/utils/dom';
import { uniqueId } from '@/3pc/js/utils/helper';
import Shower from '@/3pc/js/modules/shower';
import ConsentOverlay from '@/3pc/js/modules/consent-overlay';
import { Cookiebanner, cookiebannerTemplateMinimal, cookiebannerLabels as defaultLabels } from '@/3pc/js/modules/cookiebanner';

const hideCookiebanner = document.body.hasAttribute('data-hide-cookiebanner');
const privacyPolicyLink = 'https://www.deutsches-museum.de/datenschutz';
const imprintLink = 'https://www.deutsches-museum.de/museum/impressum';
const consentOverlays = [];

const cookiebannerLabels = {
  ...defaultLabels
};

new Cookiebanner({
  nameSpace: 'dmd',
  consentButtonTypes: 'required-tracking',
  cookiebannerTemplate: cookiebannerTemplateMinimal, 
  showTemplateMinimalInfoPanel: true,
  labels: cookiebannerLabels,
  hideCookiebanner,
  privacyPolicyLink,
  imprintLink,
  consentTypes: [
    {
      name: {
        de: 'Ablehnen',
        en: 'Reject',
      },
      text: {
        de: '<p>Wenn Sie ablehnen, nutzen Sie die Webseite mit technisch erforderlichen Cookies. Diese aktivieren die Kernfunktionalitäten der Webseite. Das Deutsche Museum kann die Nutzung der Webseite nicht analysieren und nicht verbessern.</p>',
        en: '<p>If you opt out, you will use the site with technically necessary cookies. These enable the core functions of the website. The Deutsches Museum cannot analyse the use of the website and improve it.</p>',
      },
      type: 'required',
      showInInfoPanel: true,
    },
    {
      name: {
        de: 'Zustimmen',
        en: 'Accept',
      },
      text: {
        de: '<p>Wenn Sie zustimmen, kann das Deutsche Museum mit den Cookies die Nutzung der Webseite analysieren und verbessern. Dabei bleiben Sie anonym und Ihre Daten werden vom Deutschen Museum nicht an Dritte weitergegeben.</p>',
        en: '<p>If you agree, the Deutsches Museum can use cookies to analyse and improve the use of the website. You will remain anonymous and the Deutsches Museum will not pass on your data to third parties.</p>',
      },
      type: 'matomo',
      showInInfoPanel: true,
      trackingScripts: [
        {
          insertSelector: 'body',
          script:
            'var _paq = window._paq = window._paq || [];\n' +
            "_paq.push(['trackPageView']);\n" +
            "_paq.push(['enableLinkTracking']);\n" +
            '(function() {\n' +
            `var u="https://matomo.deutsches-museum.de/";\n` +
            "_paq.push(['setTrackerUrl', u+'matomo.php']);\n" +
            `_paq.push(['setSiteId', '6']);\n` +
            "var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n" +
            "g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);\n" +
            '})();',
        },
      ],
    },
    {
      name: {
        de: 'Filme',
        en: 'Films',
      },
      text: {
        de: 'Text || Filme.',
        en: 'Text || Films.',
      },
      type: 'plyr',
    },
    {
      name: {
        de: 'Karten',
        en: 'Maps',
      },
      text: {
        de: 'Text || Karten.',
        en: 'Text || Maps.',
      },
      type: 'osm',
    },
    {
      name: {
        de: 'Eingebettete Inhalte',
        en: 'Embedded Content',
      },
      text: {
        de: 'Text || Eingebettete Inhalte.',
        en: 'Text || Embedded content.',
      },
      type: 'embed',
    },
  ], 
  onInit: () => {
    const overlays = document.querySelectorAll('[data-consent-type]');
    overlays.length &&
      overlays.forEach((overlay) => {
        const consentOverlay = new ConsentOverlay({
          container: overlay,
          nameSpace: 'dmd',
        });
        consentOverlay.init();
        consentOverlays.push(consentOverlay);
      });
  },
  onRender: banner => {
    // init cookiebanner accordions
    const infoPanelAccordions = $$('.cookiebanner__info-panel li', banner);
    infoPanelAccordions.length &&
      infoPanelAccordions.forEach(accordion => {
        const randomID = uniqueId();
        const target = $('.cookiebanner__shower-panel', accordion);
        const handler = $('button', accordion);

        setAttributes(handler, {
          id: `acc-btn-${randomID}`,
          'aria-controls': `acc-tab-${randomID}`,
        });

        setAttributes(target, {
          id: `acc-tab-${randomID}`,
          'aria-labelledby': `acc-btn-${randomID}`,
        });

        new Shower({
          target,
          handler,
          easing: 'easeInOutQuart',
          duration: 400,
          initOpened: false,
          openClassName: 'is-active',
        }).init();
      });
  },
  onOptIn: consentType => {
    consentOverlays.forEach((overlay) => {
      if (overlay.consentType === consentType.type) {
        // if there is a match, activate all overlay's of that consentType:
        overlay.toggleAll();
      }
    });
  },
  onOptOut: consentType => {
    consentOverlays.forEach((overlay) => {
      if (overlay.consentType === consentType.type) {
        // if there is a match, deactivate all overlay's of that consentType:
        overlay.toggleAll();
      }
    });
  },
}).init();
