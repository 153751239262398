const docLang = document.documentElement.lang;
const lang = docLang.length > 2 ? docLang.substring(0, 2) : docLang;
const cookiebannerLabels = {
  acceptRequired: {
    de: 'Ablehnen',
    en: 'Reject',
  },
  acceptAll: {
    de: 'Zustimmen',
    en: 'Accept',
  },
  acceptTracking: {
    de: 'Zustimmen',
    en: 'Accept',
  },
  acceptSelection: {
    de: 'Auswahl bestätigen',
    en: 'Accept selected',
  },
  status: {
    de: 'Ihre aktuelle Einstellung: ',
    en: 'Your current setting: ',
  },
  statusRequiredAccepted: {
    de: 'Nur notwendige Cookies zugelassen',
    en: 'Only required cookies accepted',
  },
  statusAllAccepted: {
    de: 'Alle Cookies zugelassen',
    en: 'All cookies accepted',
  },
  statusTrackingAccepted: {
    de: 'Auch Tracking-Cookies zugelassen',
    en: 'Tracking cookies accepted',
  },
  openConfig: {
    de: 'Einstellungen',
    en: 'Configuration',
  },
  on: {
    de: 'An',
    en: 'On',
  },
  off: {
    de: 'Aus',
    en: 'Off',
  },
  imprint: {
    de: 'Impressum',
    en: 'Imprint',
  },
  privacyPolicy: {
    de: 'Datenschutz',
    en: 'Privacy policy',
  },
  mainPanelTitle: {
    de: 'Unsere Cookie-Richtlinien',
    en: 'Our Cookie Policy',
  },
  mainPanelText: {
    de:
      '<p>Wir verwenden Cookies, um unsere Seite sicher und zuverlässig anbieten zu können und die Zugriffe auf unserer Webseite zu analysieren. Sie helfen uns dabei, die Webseite zu optimieren und Ihr persönliches Nutzungserlebnis zu verbessern. Ihre Einwilligung können Sie jederzeit widerrufen. Weitere Informationen erhalten Sie in der <a href="https://www.deutsches-museum.de/datenschutz">Datenschutzerklärung</a>.</p>',
    en:
      '<p>We use cookies to provide our site securely and reliably and to analyse traffic to our website. They help us to optimise the website and improve your personal user experience. You can revoke your consent at any time. For further information, please see the <a href="https://www.deutsches-museum.de/en/museum/privacy-policy">Privacy Policy</a>.</p>',
  },
  configPanelTitle: {
    de: 'Cookie-Einstellungen bearbeiten',
    en: 'Edit cookie settings',
  },
  configPanelText: {
    de: '<p>Optionaler Text...</p>',
    en: '<p>Optionaler Text...</p>',
  },
};

export { lang, cookiebannerLabels };
