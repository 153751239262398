const $ = require('jquery')

function copyToClipboard() {
    const textArea = document.createElement("textarea");
    textArea.value = document.querySelector('.js-copyTextarea').textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
  }

$('.js-copyToClipboard').click(copyToClipboard)
