import { lang } from '../lang/labels.js';

const cookiebannerTemplateTablist = (
  labels,
  consentTypes,
  consentButtonTypes,
  showTemplateMinimalInfoPanel,
  showTemplateConfigPanelOnRender,
  imprintLink,
  privacyPolicyLink
) => `<div class="cookiebanner cookiebanner--tablist" aria-modal="true" aria-labelledby="cookiebanner__label-main-panel" role="dialog" tabindex="0">
    <div class="cookiebanner__inner">
        <div class="cookiebanner__main-panel">
            <span id="cookiebanner__label-main-panel" class="cookiebanner__heading">${
  labels.mainPanelTitle[lang]
}</span>
            ${labels.mainPanelText[lang] ? `${labels.mainPanelText[lang]}` : ''}
            <div class="cookiebanner__cookie-section">
                <ul class="cookiebanner__tablist" role="tablist">
                  ${consentTypes
  .map(
    (consentType, index) =>
      `<li class="cookiebanner__tablist-item" role="presentation">
                          <div class="cookiebanner__tablist-head">
                            <button
                                id="cookiebanner__tab-${index}"
                                class="cookiebanner__tab cookiebanner__cookie-label"
                                aria-controls="cookiebanner__tabpanel-${index}"
                                aria-selected="false"
                                role="tab"
                                >
                                <span>${consentType.name[lang]}</span>
                            </button>
                             <input type="checkbox" id="${
                                consentType.type
                              }-enabled"  ${
                                consentType.type === 'required'
                                  ? 'checked readonly disabled'
                                  : ''
                              } ${
                                consentType.isChecked ? 'checked' : ''
                              } data-type="${consentType.type}" class="sr-only">
                              <label for="${
                                consentType.type
                              }-enabled" class="cookiebanner__checkbox">
                                <span class="sr-only">${
                                  consentType.name[lang]
                                }</span>
                                <span class="cookiebanner__toggler" aria-hidden="true"></span>
                                <span class="cookiebanner__toggler-text is-on" aria-hidden="true" tabindex="-1">${
                                  labels.on[lang]
                                }</span>
                                <span class="cookiebanner__toggler-text is-off" aria-hidden="true" tabindex="-1">${
                                  labels.off[lang]
                                }</span>
                            </label>
                          </div>
                          <div class="cookiebanner__tabpanel" id="cookiebanner__tabpanel-${index}" aria-labelledby="cookiebanner__tab-${index}" aria-hidden="true" role="tabpanel">
                          ${consentType.text[lang]}
                          </div>
                      </li>`
  )
  .join('')}
                </ul>
            </div>

            <div class="cookiebanner__buttons">
              <button data-consent="acceptSelection">${
                labels.acceptSelection[lang]
              }</button>
              <button data-consent="acceptAll" >${
                labels.acceptAll[lang]
              }</button>
            </div>
             ${
  imprintLink || privacyPolicyLink
    ? `
                <div class="cookiebanner__links">
                    ${
      privacyPolicyLink
        ? `<a href=${privacyPolicyLink} target="_blank">${labels.privacyPolicy[lang]}</a>`
        : ''
    }
                    ${
      imprintLink
        ? `<a href=${imprintLink} target="_blank">${labels.imprint[lang]}</a>`
        : ''
    }
                </div>
                `
    : ''
}
        </div>
    </div>
</div>`;

export { cookiebannerTemplateTablist };
