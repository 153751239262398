function changeTab(evt) {
  const activeTab = document.querySelector('.tab__content.active')
  const activeTabBtn = document.querySelector('.tab__button.active')
  if (activeTab && activeTab.id === evt.target.dataset.targetId) return  // tab didn't change

  // hide current tab
  if (activeTab) activeTab.classList.remove('active')
  if (activeTabBtn) activeTabBtn.classList.remove('active')

  // display target tab
  evt.target.classList.add('active')
  const tab = document.getElementById(evt.target.dataset.targetId)
  tab.classList.add("active")
}

document.querySelectorAll('.tab__button').forEach(el =>
  el.addEventListener('click', changeTab)
)