import {selectors} from "@/elements";

// attach trailing icons to rich text links
document.querySelectorAll(selectors.richTextEmailLinks).forEach(el => {
  el.innerHTML = `<span>${el.innerHTML}</span><i class="icon-mail" aria-hidden="true"></i>`
})
document.querySelectorAll(selectors.richTextDownloadLinks).forEach(el => {
  let innerHTML = el.innerHTML
  if (el.dataset.fileExtension && el.dataset.size) {
    innerHTML += ` (${el.dataset.fileExtension} ${el.dataset.size})`
  }
  el.innerHTML = `<span>${innerHTML}</span><i class="icon-arrow-down" aria-hidden="true"></i>`
})
document.querySelectorAll(selectors.richTextInternalLinks).forEach(el => {
  if (el.parentElement.tagName === "LI") {
    el.innerHTML = `<span>${el.innerHTML}</span><i class="icon-arrow-right" aria-hidden="true"></i>`
    el.classList.remove('link--intern')
    el.classList.add('link--distribution')
  } else {
    el.innerHTML = `<span>${el.innerHTML}</span>`
  }
})
document.querySelectorAll(selectors.richTextExternalLinks).forEach(el => {
  el.innerHTML = `<span>${el.innerHTML}</span><i class="icon-arrow-up-right" aria-hidden="true"></i>`
})
