import Autosuggest from "@/3pc/js/modules/AutoSuggest";
import { $ } from '@/3pc/js/utils/dom';

const searchForm = document.querySelector('.hero-image-search form')

if (searchForm) {
    new Autosuggest({
        // input which triggers solr
        input: $('input', searchForm),
        // form element which contains the input
        form: searchForm,
        // [optional] set true, if you want to enable typeahead
        typeahead: false,
        // [optional] min length of text until we fire the ajax-request (2 is default)
        textMinLength: 0,
        // [optional] prevent AutoSuggest from submitting the form (e.g. if you want to trigger ajax instead)
        preventSubmit: false,
        // [optional] submit form if user clicks on a suggestion button (default is false)
        submitOnSuggestionClick: true,
        // [optional] highlighted letters in autosuggest will be case sensitive (e.g. upper- lowercase) (default is false)
        caseSensitive: false,
    }).init();
}
