const $ = require('jquery')

// object teaser
var $objectTeaserLoadMore = $(".object-teaser-load-more");

function objectTeaserLoadMore(e) {
  var $objectTeaser = $(e.target.closest('.teaser-objects'));
  $objectTeaser.find(".teaser-objects--item:hidden").slice(0, $(e.target).data("page-size")).show();

  // hide load more button
  if ($objectTeaser.find(".teaser-objects--item:hidden").length == 0) {
    $objectTeaser.find(".object-teaser-load-more").hide();
  }

  // update grid
  var event = new Event("resize");
  window.dispatchEvent(event);
}

$objectTeaserLoadMore.on("click", objectTeaserLoadMore);
$objectTeaserLoadMore.click();
