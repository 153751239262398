import Masonry from "masonry-layout"
import {$$} from "@/3pc/js/utils/dom";

const grids = $$('.grid')

if (grids.length) {
    grids.forEach(elem => {
        new Masonry(elem, {
            // options
            itemSelector: '.grid-item',
            columnWidth: 100
        });
    })
}
