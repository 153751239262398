import {uniqueId} from '@/3pc/js/utils/helper';
import Shower from '@/3pc/js/modules/shower';
import {$, $$, setAttributes} from "@/3pc/js/utils/dom";
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
const accordionItems = $$('.logical-units__group');

if (accordionItems.length) {
  accordionItems.forEach(item => {
    const randomID = uniqueId();
    const handler = $('.logical-units__btn', item);
    const target = $('.logical-units__panel', item);

    if (!target) {
      return  // in case there is no sublist
    }

    setAttributes(handler, {
      id: `acc-btn-${randomID}`,
      'aria-controls': `acc-tab-${randomID}`,
    });

    setAttributes(target, {
      id: `acc-tab-${randomID}`,
      'aria-labelledby': `acc-btn-${randomID}`,
    });

    new Shower({
      target,
      handler,
      easing: 'easeInOutQuart',
      duration: 400,
      initOpened: item.classList.contains("is-open"),
    }).init();
  });
}
