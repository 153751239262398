import { lang } from '../lang/labels.js';

const cookiebannerTemplateMinimal = (
  labels,
  consentTypes,
  consentButtonTypes,
  showTemplateMinimalInfoPanel,
  showTemplateConfigPanelOnRender,
  imprintLink,
  privacyPolicyLink,
  status
) => `<div class="cookiebanner cookiebanner--minimal" aria-modal="true" aria-labelledby="cookiebanner__label-main-panel" role="dialog" tabindex="0">
  <div class="cookiebanner__inner">
    <div class="cookiebanner__main-panel">
      <span id="cookiebanner__label-main-panel" class="cookiebanner__heading">${
      labels.mainPanelTitle[lang]
    }</span>
      ${labels.mainPanelText[lang] ? `${labels.mainPanelText[lang]}` : ''}
        ${
        status && status === 'deny'
          ? `<p class="cookiebanner__status"><strong>${labels.status[lang]} ${labels.statusRequiredAccepted[lang]}</strong></p>`
          : status && status === 'acceptTracking'
            ? `<p class="cookiebanner__status"><strong>${labels.status[lang]} ${labels.statusTrackingAccepted[lang]}</strong></p>`
            : status && status === 'acceptAll'
              ? `<p class="cookiebanner__status"><strong>${labels.status[lang]} ${labels.statusAllAccepted[lang]}</strong></p>`
              : ''
      }
      <div class="cookiebanner__buttons">
        <button data-consent="deny">${labels.acceptRequired[lang]}</button>
        ${
  consentButtonTypes === 'required-tracking'
    ? ` <button data-consent="acceptTracking">${labels.acceptTracking[lang]}</button>`
    : ''
}
        ${
  consentButtonTypes === 'required-all'
    ? ` <button data-consent="acceptAll">${labels.acceptAll[lang]}</button>`
    : ''
}
      </div>
      ${
  showTemplateMinimalInfoPanel
    ? `
      <div class="cookiebanner__info-panel">
          <ul class="cookiebanner__list">
        ${consentTypes
      .map((consentType) =>
        consentType.showInInfoPanel
          ? `<li class="cookiebanner__list-item">
                <button class="cookiebanner__shower-button" aria-expanded="false">
                    <span>${consentType.name[lang]}</span>
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                      <path fill="currentColor" d="M 7,8.167306 12.444903,2.722403 14,4.2775 l -7,7 -7,-7 1.5550972,-1.555097 z" />
                    </svg>
                  </button>
                <div class="cookiebanner__shower-panel">
                    <div class="cookiebanner__shower-panel-inner">
                        ${consentType.text[lang]}
                    </div>
                </div>
            </li>`
          : ''
      )
      .join('')}
            </ul>
        </div>
             ${
      imprintLink || privacyPolicyLink
        ? `
                <div class="cookiebanner__links">
                    ${
          privacyPolicyLink
            ? `<a href=${privacyPolicyLink} target="_blank">${labels.privacyPolicy[lang]}</a>`
            : ''
        }
                    ${
          imprintLink
            ? `<a href=${imprintLink} target="_blank">${labels.imprint[lang]}</a>`
            : ''
        }
                </div>
                `
        : ''
    }
      `
    : ''
}
    </div>
  </div>
</div>`;

export { cookiebannerTemplateMinimal };
