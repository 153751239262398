import { cookiebannerTemplateMinimal } from './templates/template-minimal.js';
import { cookiebannerTemplateMinimalConfig } from './templates/template-minimal-config';
import { cookiebannerTemplateTablist } from './templates/template-tablist.js';
import { lang, cookiebannerLabels } from './lang/labels.js';
import './style/cookiebanner.scss';

class Cookiebanner {
  constructor(params) {
    this.nameSpace = params.nameSpace || 'cookiebanner';
    this.cookiebannerClass = params.cookiebannerClass || '.cookiebanner';
    this.consentTypes = params.consentTypes;
    this.consentButtonTypes = params.consentButtonTypes || 'required-all';
    this.cookiebannerTemplate =
      params.cookiebannerTemplate || cookiebannerTemplateMinimal;
    this.cookiebannerTabClass =
      params.cookiebannerTabClass || '.cookiebanner__tab';
    this.cookiebannerTabpanelClass =
      params.cookiebannerTabpanelClass || '.cookiebanner__tabpanel';
    this.openerButtons =
      params.openerButtons ||
      document.querySelectorAll('[data-cookiebanner-opener]');
    this.onInit = params.onInit || null;
    this.onRender = params.onRender || null;
    this.onOptIn = params.onOptIn || null;
    this.onOptOut = params.onOptOut || null;
    this.onTrackingEnabled = params.onTrackingEnabled || null;
    this.showTemplateMinimalInfoPanel =
      params.showTemplateMinimalInfoPanel || false;
    this.hideCookiebanner = params.hideCookiebanner || false;
    this.imprintLink = params.imprintLink || null;
    this.privacyPolicyLink = params.privacyPolicyLink || null;
    this.labels = params.labels || cookiebannerLabels;
    this.scripts = [];
    this.firstRun = true;
  }

  init = () => {
    this.onInit && this.onInit();
    if (this.checkStorage('required')) {
      this.setDefaultAttributes();

      this.consentTypes.forEach((consentType) => {
        if (this.checkStorage(consentType.type)) {
          if (consentType.trackingScripts) {
            this.createTrackingScripts(consentType.trackingScripts);
            this.onTrackingEnabled && this.onTrackingEnabled(consentType.type);
          } else {
            this.onOptIn && this.onOptIn(consentType);
          }
        }
      });
    } else {
      this.setDefaultAttributes();
      this.renderCookiebanner(false);
    }
    this.bindOpenButton();
  };

  setDefaultAttributes = (resetAll = true) => {
    this.consentTypes.forEach((consentType) => {
      if (resetAll) {
        // reset all
        consentType.isChecked = false;
        consentType.alreadySet = false;
      } else {
        if (this.checkStorage(consentType.type)) {
          consentType.isChecked = true;
          consentType.alreadySet = true;
        } else {
          consentType.isChecked = false;
          consentType.alreadySet = false;
        }
      }
    });
  };

  bindOpenButton = () => {
    this.openerButtons.length &&
      this.openerButtons.forEach((openerButton) => {
        openerButton.addEventListener('click', () => {
          this.firstRun = false;
          this.setDefaultAttributes(false);
          this.renderCookiebanner();
        });
      });
  };

  renderCookiebanner = (showTemplateConfigPanelOnRender = true) => {
    // insert cookiebanner HTML
    // note: if a user visites the domain for the very first time, the cookiebanner should pop up on pageload. but NOT on pages containing the privacy policy and the imprint (pages where this.hideCookiebanner is true). here the cookiebanner should nevertheless be available on click (!this.firstRun).
    if (!this.hideCookiebanner || !this.firstRun) {
      document.body.insertAdjacentHTML(
        'beforeend',
        this.cookiebannerTemplate(
          this.labels,
          this.consentTypes,
          this.consentButtonTypes,
          this.showTemplateMinimalInfoPanel,
          showTemplateConfigPanelOnRender,
          this.imprintLink,
          this.privacyPolicyLink,
          window.localStorage.getItem(`${this.nameSpace}-status`)
        )
      );

      this.banner = document.querySelector(this.cookiebannerClass);
      this.bindCookiebannerEvents();
      this.onRender && this.onRender(this.banner);
    }
  };

  bindCookiebannerEvents = () => {
    this.handleKeyboardNavigation();
    if (this.cookiebannerTemplate === cookiebannerTemplateTablist)
      this.initTabpanel();
    this.handleBodyScroll();
    this.bindCloseButtons();
    this.bindShowConfigButton();
  };

  handleKeyboardNavigation = () => {
    this.banner && this.banner.focus();
    const focusableElements = this.banner.querySelectorAll(
      'button, [href], input:not([disabled]), select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    this.banner.addEventListener('keydown', (e) => {
      // trap focus
      if (e.key === 'Tab' && e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else if (e.key === 'Tab') {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    });
  };

  handleBodyScroll = () => {
    if (window.innerWidth <= 768) {
      document.querySelector(this.cookiebannerClass)
        ? (document.querySelector('html').style.overflow = 'hidden')
        : (document.querySelector('html').style.overflow = '');
    }
  };

  initTabpanel = () => {
    // handle tab panels
    const tabButtons = document.querySelectorAll(this.cookiebannerTabClass);
    const tabPanels = document.querySelectorAll(this.cookiebannerTabpanelClass);

    // set first panel to active by default
    tabButtons[0].setAttribute('aria-selected', 'true');
    tabPanels[0].setAttribute('aria-hidden', 'false');

    tabButtons.forEach((tabButton) => {
      tabButton.addEventListener('click', () => {
        const target = tabButton.getAttribute('aria-controls');

        // reset all
        tabButtons.forEach((tabButton) =>
          tabButton.setAttribute('aria-selected', 'false')
        );
        tabPanels.forEach((tabPanel) =>
          tabPanel.setAttribute('aria-hidden', 'true')
        );

        // set selected tab to active
        tabButton.setAttribute('aria-selected', 'true');
        document
          .querySelector(`#${target}`)
          .setAttribute('aria-hidden', 'false');
        document.querySelector(`#${target}`).focus();
      });
    });
  };

  bindShowConfigButton = () => {
    const showConfigButton = this.banner.querySelector('[data-show-config]');
    showConfigButton &&
      showConfigButton.addEventListener('click', () => {
        this.banner.parentNode.removeChild(this.banner);
        this.renderCookiebanner();
        // this.banner
        //   .querySelector('.cookiebanner__main-panel')
        //   .classList.add('is-hidden');
        // this.banner
        //   .querySelector('.cookiebanner__config-panel')
        //   .classList.remove('is-hidden');
      });
  };

  bindCloseButtons = () => {
    const closeButtons = document.querySelectorAll('[data-consent]');
    closeButtons.length &&
      closeButtons.forEach((button) => {
        button.addEventListener('click', () => {
          const choice = button.dataset.consent;
          const checkboxes = this.banner.querySelectorAll(
            'input[type="checkbox"]',
            this.banner
          );

          if (choice === 'deny') {
            this.consentTypes.forEach((consentType) => {
              consentType.type === 'required'
                ? (consentType.isChecked = true)
                : (consentType.isChecked = false);
            });
          } else if (choice === 'acceptTracking') {
            this.consentTypes.forEach((consentType) => {
              consentType.type === 'required' || consentType.trackingScripts
                ? (consentType.isChecked = true)
                : (consentType.isChecked = false);
            });
          } else if (choice === 'acceptAll') {
            checkboxes.length &&
              checkboxes.forEach((checkbox) => {
                checkbox.checked = true;
              });
            this.consentTypes.forEach((consentType) => {
              consentType.isChecked = true;
            });
          } else if (choice === 'acceptSelection') {
            checkboxes.length &&
              checkboxes.forEach((checkbox, index) => {
                this.consentTypes[index].isChecked = checkbox.checked;
              });
          }

          this.closeCookiebanner(choice);
        });
      });
  };

  closeCookiebanner = (choice) => {
    this.clearStorage();

    this.consentTypes.forEach((consentType) => {
      if (consentType.isChecked) {
        this.setStorage(consentType.type);
      }
    });
    window.localStorage.setItem(`${this.nameSpace}-status`, choice);

    this.handleExternalScripts();
    this.banner.parentNode.removeChild(this.banner);
    this.handleBodyScroll();
  };

  handleExternalScripts = () => {
    this.consentTypes.forEach((consentType) => {
      if (!consentType.alreadySet && consentType.isChecked) {
        // opt-in:
        if (consentType.trackingScripts) {
          this.handleTrackingOptIn(consentType);
        } else {
          this.onOptIn && this.onOptIn(consentType);
        }
        consentType.alreadySet = true;
      } else if (consentType.alreadySet && !consentType.isChecked) {
        // opt-out:
        if (consentType.trackingScripts) {
          this.handleTrackingOptOut(consentType);
        } else {
          this.onOptOut && this.onOptOut(consentType);
        }
        consentType.alreadySet = false;
      }
    });
  };

  handleTrackingOptIn = (consentType) => {
    this.createTrackingScripts(consentType.trackingScripts);
    // if a user who previously opted out, opts in again: remove mtm_consent_removed (matomo) or ga-disable (google-analytics) cookie:
    if (consentType.type === 'matomo') {
      if (document.cookie.indexOf('mtm_consent_removed') > -1) {
        // eslint-disable-next-line no-undef
        _paq.push(['forgetUserOptOut']);
      }
    } else if (consentType.type === 'googleanalytics') {
      const googleTrackingId = document.body.dataset.trackingId;
      if (
        googleTrackingId &&
        document.cookie.indexOf('ga-disable-' + googleTrackingId + '=true') > -1
      ) {
        document.cookie =
          'ga-disable-' +
          googleTrackingId +
          '=false; expires=Thu, 01 Jan 1970 00:00:01 UTC; path=/';
        window['ga-disable-' + googleTrackingId] = false;
      }
    }
  };

  handleTrackingOptOut = (consentType) => {
    if (consentType.type === 'matomo') {
      // eslint-disable-next-line no-undef
      _paq.push(['optUserOut']);
    } else if (consentType.type === 'googleanalytics') {
      // eslint-disable-next-line no-undef
      gaOptout();
    }
    // make sure that tracking scripts are removed immediately without page reload:
    this.scripts.length &&
      this.scripts.forEach((script) => script.parentNode.removeChild(script));
  };

  setStorage = (type) => {
    window.localStorage.setItem(`${this.nameSpace}-${type}-enabled`, true);
  };

  checkStorage = (type) => {
    return window.localStorage.getItem(`${this.nameSpace}-${type}-enabled`);
  };

  clearStorage = () => {
    const allLocalStorageItems = { ...window.localStorage };
    const consentTypesInStorage = Object.keys(allLocalStorageItems).filter(
      (key) => key.includes(this.nameSpace)
    );
    consentTypesInStorage.length &&
      consentTypesInStorage.forEach((item) => {
        window.localStorage.removeItem(item);
      });
  };

  createTrackingScripts = (trackingScripts) => {
    if (trackingScripts.length) {
      trackingScripts.forEach((tracking) => {
        const insertSelector = tracking.insertSelector;

        if (tracking.scriptLink) {
          this.createScriptLink('script', tracking.scriptLink, insertSelector);
        }

        if (tracking.script) {
          this.createScriptTag('script', tracking.script, insertSelector);
        }

        if (tracking.noscript) {
          this.createScriptTag('noscript', tracking.noscript, insertSelector);
        }
      });
    }
  };

  createScriptTag = (tagType, script, insertSelector) => {
    const newScript = document.createElement(tagType);
    const inlineScript = document.createTextNode(script);

    newScript.appendChild(inlineScript);
    document.querySelector(insertSelector).appendChild(newScript);
    this.scripts.push(newScript);
  };

  createScriptLink = (tagType, script, insertSelector) => {
    const newScript = document.createElement(tagType);
    newScript.setAttribute('async', '');
    newScript.src = script;
    document.querySelector(insertSelector).appendChild(newScript);
    this.scripts.push(newScript);
  };
}

export {
  Cookiebanner,
  cookiebannerTemplateTablist,
  cookiebannerTemplateMinimalConfig,
  cookiebannerTemplateMinimal,
  lang,
  cookiebannerLabels,
};
