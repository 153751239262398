export const selectors = {
  richTextEmailLinks: '.link--mail',
  richTextDownloadLinks: '.link--download',
  richTextInternalLinks: '.link--intern',
  richTextExternalLinks: '.link--extern',
};


export const objectImageElements = {
  id: 'openseadragon',
  media: 'openseadragon-media',
  zoomInButton: 'zoom-in',
  zoomOutButton: 'zoom-out',
  nextButton: 'next-button',
  previousButton: 'previous-button',
  fullPageButton: 'full-page-button',
  toolbar: 'openseadragon-toolbar',
  homeButton: 'home-button',
  firstPageButton: 'first-page-button',
  lastPageButton: 'last-page-button',
  downloadButton: 'download-button'
}